.fourth {
    display: flex;
    flex-direction: column;
    margin-top: 180px;

    @media (max-width: 480px) {
        margin-top: 144px;
    }

    .teamScene {
        position: relative;

        @media (max-width: 480px) {
            transform: scale(0.5);
        }

        .image1 {
            position: absolute;
            width: 60%;
            left: 25%;
            top: 25px;

            @media (max-width: 480px) {
                width: 100%;
                left: 0;
            }
        }

        .image2 {
            position: absolute;
            width: 47px;
            height: 47px;
            left: 10%;
            top: 300px;

            @media (max-width: 480px) {
                left: -15%;
            }
        }

        .image3 {
            position: absolute;
            width: 27px;
            height: 27px;
            right: 10%;
            top: 200px;

            @media (max-width: 480px) {
                right: -10%;
            }
        }
    }
    
    &__content {
        margin-top: 75px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 480px) {
            flex-direction: column;
            justify-content: flex-start;
            margin-top: 20px;
        }

        @media (min-width: 481px) and (max-width: 860px) {
            flex-direction: column;
            justify-content: flex-start;
        }

        &--left {
            width: 747px;
            height: 545px;

            @media (max-width: 480px) {
                width: 100%;
                height: 230px;
            }

            @media (min-width: 481px) and (max-width: 860px) {
                width: 100%;
            }
    
        }

        &--right {
            padding-left: 35px;
            padding-bottom: 75px;
            width: 50%;
            display: flex;
            height: inherit;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            max-width: 490px;

            @media (max-width: 480px) {
                width: 100%;
                padding-left: 0;
            }

            @media (min-width: 481px) and (max-width: 860px) {
                width: 100%;
                flex-direction: row;
                padding: 0;
                justify-content: space-between;
                max-width: 100%;
                align-items: flex-start;
            }

            @media (min-width: 861px) and (max-width: 1560px) {
                justify-content: flex-start;
                padding-top: 50px;
            }

            &__title {
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                letter-spacing: 0.3px;
                margin-bottom: 60px;
                background: -webkit-linear-gradient(0deg,#4328BC,#2F87ED 70%, #9BF9FF 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media (max-width: 480px) {
                    font-size: 20px;
                    margin-bottom: 40px;
                }

                @media (min-width: 481px) and (max-width: 860px) {
                    font-size: 20px;
                    margin-bottom: 0;
                    width: 47%;
                }

                @media (min-width: 861px) and (max-width: 1560px) {
                    font-size: 23px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 34.5px */
                    letter-spacing: 0.23px;
                    margin-bottom: 50px;
                }
            }

            &__wrapper {
                @media (min-width: 481px) and (max-width: 860px) {
                    width: 47%;
                }
            }

            p {
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: 0.16px;
                margin-top: 16px;

                @media (max-width: 480px) {
                    font-size: 13px;
                }

                @media (min-width: 481px) and (max-width: 860px) {
                    font-size: 14px;
                    margin-top: 0;
                    margin-bottom: 16px;
                }

                @media (min-width: 861px) and (max-width: 1560px) {
                    font-size: 14px;
                    letter-spacing: 0.14px;
                }
            }
        }
    }
}