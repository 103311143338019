

[data-theme='dark'] {
    --text: #4328BC;
    --textSecond: #A080E3;
}

:root {
    --text: #4328BC;
    --textSecond: #A080E3;
}