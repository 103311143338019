.container {
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;

    @media (min-width: 481px) and (max-width: 860px) {
        max-width: 90%;
    }

    @media (min-width: 861px) and (max-width: 1560px) {
        max-width: 90%;
    }
}

.App {
    display: flex;
    flex-direction: column;
    position: relative;
    
    &.home {
        background-image: url('./assets/homepageBg.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        overflow: hidden;

        @media (max-width: 480px) {
            background-image: url('./assets/375homepage.jpg');
        }

        @media (min-width: 481px) and (max-width: 860px) {
            background-image: url('./assets/768homepage.jpg');
        }

        @media (min-width: 861px) and (max-width: 1560px) {
            background-image: url('./assets/1024homepage.jpg');
        }
    }

    &.terms {
        background-image: url('./assets/1024terms.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }


    &-header {
        display: flex;
        padding: 22px 0;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        transition: top 0.3s ease-in-out;
        z-index: 11;

        .view-research-container {
            display: flex;
            align-items: center;
            // margin-top: -1px;
        }        

        .view-research {
            -webkit-text-fill-color: #fff;
            color: #FFF;            
            text-align: center;
            font-family: Manrope;
            font-size: 10px;
            font-style: normal;
            font-weight: 800;
            line-height: 100%; /* 10px */
            letter-spacing: 0.2px;
            text-transform: uppercase;
            border-radius: 5px;
            background: linear-gradient(270deg, #FFF068 9.33%, #C274DE 34.02%, #A261FF 100%), #FFF;
            margin-left: 6px;
            width: 36px;
            height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        &.activeHeader {
            &::before {
                content: '';
                position: fixed;
                left: -50vw;
                top: 0px;
                background: rgba(255, 255, 255, 0.97);
                width: 150vw;
                height: 88px;
                z-index: -1;

                @media (max-width: 480px) {
                    height: 52px;
                }
            }
        }

        a {
            cursor: pointer;
            white-space: nowrap;

            .icon {
                color:#4328BC;
                pointer-events:none;
                margin-left: 2px;
                width: 24px;
                height: 24px;
            }
        }

        @media (min-width: 861px) and (max-width: 1560px) {
            img {
                width: 99px;
                height: 33px;
            }

            li {
                a {
                    font-size: 13px;                  
                }
            }
        }

        @media (min-width: 481px) and (max-width: 860px) {

            img {
                width: 78px;
                height: 27px;
            }
            
            ul {
                width: fit-content !important;
            }

            li {

                .icon-container {
                    margin-top: -2px;
                }   
                a {
                    font-size: 12px;
                    margin-left: 28px;
                    display: block;
                    width: max-content;
                    .icon {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        @media (max-width: 480px) {
            padding-top: 14px;
            padding-bottom: 14px;
            flex-direction: column;
            margin: 0;

            .firstRow {
                display: flex;
                width: 90%;
                justify-content: space-between;
            }

            ul {
                display: flex;
                flex-direction: column;
                justify-content: flex-start !important;
                align-items: center;
                width: 100%;

                li {
                    margin-top: 58px;
                }
            }

            a {
                font-size: 18px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 0.18px; 
            }

            &.openedMenu {
                background: url('./assets/burgerBg.png');
                background-size: cover;
            }

            .burgerMenuToggler {
                cursor: pointer;
            }

            .burgerMenu {
                height: calc(100vh - 57px);
                width: 100%;
                display: none;

                &.show {
                    display: flex;
                    justify-content: center;
                }
            }

            a {
                img {
                    width: 67px;
                    height: 23px;
                }
            }

            img {
                z-index: 2;
            }
        }

        ul {
            display: flex;
            list-style-type: none;
            width: 56%;
            justify-content: space-between;
        }
    }
}

.terms {
    padding-top: 80px;
    
    &__title {
        color: var(--text);
        font-size: 60px;
        font-style: normal;
        font-weight: 800;
        line-height: 83px;
        letter-spacing: 0.6px;
        font-family: Articulat CF;

        @media (max-width: 480px) {
            font-size: 34px;
            line-height: 40px;
        }

        @media (min-width: 481px) and (max-width: 860px) {
            font-size: 35px;
            line-height: 51px;
        }

        @media (min-width: 861px) and (max-width: 1560px) {
            font-size: 44px;
        }
    }

    &__date {
        color: var(--text);
        font-family: Manrope;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 83px;
        letter-spacing: 0.3px;

        @media (max-width: 480px) {
            font-size: 17px;
        }

        @media (min-width: 481px) and (max-width: 860px) {
            font-size: 18px;
            line-height: 51px;
        }

        @media (min-width: 861px) and (max-width: 1560px) {
            font-size: 22px;
        }
    }

    p {
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        color: var(--text);
        margin-bottom: 20px;

        @media (max-width: 480px) {
            font-size: 13px;
        }

        @media (min-width: 481px) and (max-width: 860px) {
            font-size: 14px;
            margin-bottom: 16px;
        }

        @media (min-width: 861px) and (max-width: 1560px) {
            font-size: 14px;
            margin-bottom: 18px;
        }
    }

    h2 {
        font-family: Manrope;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: 0.2px;
        color: var(--text);
        margin-bottom: 20px;

        @media (max-width: 480px) {
            font-size: 15px;
        }

        @media (min-width: 481px) and (max-width: 860px) {
            font-size: 16px;
            margin-bottom: 16px;
        }

        @media (min-width: 861px) and (max-width: 1560px) {
            font-size: 14px;
            margin-bottom: 18px;
        }
    }

    h3 {
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;
        letter-spacing: 0.16px;
        margin-bottom: 20px; 
        color: var(--text);

        @media (max-width: 480px) {
            font-size: 13px;
        }

        @media (min-width: 481px) and (max-width: 860px) {
            font-size: 9px;
            margin-bottom: 16px;
        }

        @media (min-width: 861px) and (max-width: 1560px) {
            font-size: 12px;
            margin-bottom: 18px;
        }
    }

    ul {
        list-style-type: none;
        margin-bottom: 20px;

        @media (min-width: 481px) and (max-width: 860px) {
            font-size: 16px;
        }

        @media (min-width: 861px) and (max-width: 1560px) {
            margin-bottom: 18px;
        }
        
        li {
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: 0.16px;
            

            @media (max-width: 480px) {
                font-size: 13px;
            }

            @media (min-width: 481px) and (max-width: 860px) {
                font-size: 14px;
            }

            @media (min-width: 861px) and (max-width: 1560px) {
                font-size: 14px;
            }
        }
    }
}

.footer {
    &__content {
        display: flex;
        justify-content: space-between;
        margin-top: 140px;
        padding-bottom: 70px;

        @media (max-width: 480px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }


        &--left {
            display: flex;
            flex-direction: column;

            &__socials {
                width: 100%;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 25px;

                @media (max-width: 480px) {
                    margin-top: 20px;
                }

                @media (min-width: 481px) and (max-width: 860px) {
                    margin-top: 15px;
                }

                @media (min-width: 861px) and (max-width: 1560px) {
                    margin-top: 20px;
                }

                svg {
                    cursor: pointer;
                }
            }
        }

        &--right {
            display: flex;
            flex-direction: column;

            @media (max-width: 480px) {
                margin-top: 35px;
            }

            &__links {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                @media (max-width: 480px) {
                   justify-content: space-between;
                }
            }

            a {
               margin-left: 20px;
               color: #4F95FF;
               font-family: Manrope;
               font-size: 12px;
               font-style: normal;
               font-weight: 500;
               line-height: 120%;
               letter-spacing: 0.12px;
               cursor: pointer;

               @media (max-width: 480px) {
                    margin-left: 0px;
                    font-size: 9px;
                }

                @media (min-width: 481px) and (max-width: 860px) {
                    font-size: 11px;
                }

                @media (min-width: 861px) and (max-width: 1560px) {
                    font-size: 10px;
                }
            }

            p {
                margin-top: 16px;
                color: #B6BCC3;
                text-align: justify;
                font-family: Manrope;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: 0.12px;
                max-width: 510px;

                @media (max-width: 480px) {
                    font-size: 8px;
                }

                @media (min-width: 481px) and (max-width: 860px) {
                    font-size: 9px;
                    max-width: 400px;
                }

                @media (min-width: 861px) and (max-width: 1560px) {
                    font-size: 9px;
                    max-width: 400px;
                }
            }
        }
    }
}