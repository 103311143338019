.title {
    display: flex;
    flex-direction: column;

    &__label {
        color: #4F95FF;
        font-family: Manrope;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 75.3%;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (max-width: 480px) {
            font-size: 14px;
            letter-spacing: 0.14px;
        }

        @media (min-width: 481px) and (max-width: 860px) {
            font-size: 16px;
            letter-spacing: 0.16px;
        }

        @media (min-width: 861px) and (max-width: 1560px) {
            font-size: 18px;
            letter-spacing: 0.18px;
        }
    }

    h2 {
        margin-top: 16px;
        color: var(--text);
        font-family: Manrope;
        font-size: 70px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: 0.7px;

        @media (max-width: 480px) {
            font-size: 35px;
            letter-spacing: 0.35px;
        }

        @media (min-width: 481px) and (max-width: 860px) {
            font-size: 48px;
            letter-spacing: 0.48px;
        }

        @media (min-width: 861px) and (max-width: 1560px) {
            margin-top: 12px;
            font-size: 53px;
            letter-spacing: 0.53px;
        }
    }

    span {
        padding-left: 8px;
    }
}