.second {
    display: flex;
    flex-direction: column;

    @media (max-width: 480px) {
        margin-top: 133px;
    }

    @media (min-width: 481px) and (max-width: 860px) {
        margin-top: 198px;
    }

    @media (min-width: 861px) and (max-width: 1560px) {
        margin-top: 180px;
    }

    .aboutScene {
        position: relative;
        width: inherit;
        height: inherit;

        @media (max-width: 480px) {
            transform: scale(0.5); /* Adjust this value as needed */
            transform-origin: top left;
        }

        @media (min-width: 1201px) and (max-width: 1560px) {
            transform: scale(0.8); /* Adjust this value as needed */
            transform-origin: top left;
        }

        @media (min-width: 861px) and (max-width: 1200px) {
            transform: scale(0.6); /* Adjust this value as needed */
            transform-origin: top left;
        }

        .about_image1 {
            position: absolute;
            width: 283px;
            height: 165px;
            left: 426px;
            top: 175px;
            z-index: 3;
        }

        .about_image2 {
            position: absolute;
            width: 405px;
            height: 486px;
            left: 131px;
            top: 84px;
            z-index: 1;
        }

        .about_image3 {
            position: absolute;
            width: 258px;
            height: 243px;
            left: 340px;
            top: 272px;
            z-index: 2;
        }

        .about_image4 {
            position: absolute;
            width: 40px;
            height: 40px;
            left: 25px;
            top: 322px;
            z-index: 2;
            
        }

        .about_image5 {
            position: absolute;
            width: 40px;
            height: 40px;
            left: 626px;
            top: 370px;
            z-index: 2;

        }

        .about_image6 {
            position: absolute;
            width: 211px;
            height: 148px;
            left: 41px;
            bottom: 24px;
            z-index: 2;

            @media (max-width: 480px) {
                top: 410px;
                bottom: 0;
            }

        }

        .about_image7 {
            position: absolute;
            width: 214px;
            height: 212px;
            left: 13px;
            top: 32px;
            z-index: 2;

            @media (min-width: 481px) and (max-width: 860px) {
                width: 191px;
                font-size: 13px;
            }
        }
    }
    
    &__content {
        margin-top: 55px;
        display: flex;

        @media (max-width: 480px) {
            margin-top: 0px;
            flex-direction: column;
        }

        @media (min-width: 481px) and (max-width: 860px) {
            flex-direction: column;
        }

        &--left {
            width: 794px;
            height: 592px;

            @media (max-width: 480px) {
                width: 100%;
                height: 330px;
            }

            @media (min-width: 861px) and (max-width: 1560px) {
                width: 100%;
            }
        }

        &--right {
            padding-left: 35px;
            padding-bottom: 75px;
            width: -moz-fit-content;
            width: min-content;
            display: flex;
            height: inherit;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            min-width: 40%;

            @media (max-width: 480px) {
                width: 100%;
                padding-left: 0;
            }

            @media (min-width: 481px) and (max-width: 860px) {
                width: 100%;
                padding-left: 0;
            }

            @media (min-width: 861px) and (max-width: 1200px) {
                justify-content: center;
            }

            @media (min-width: 1201px) and (max-width: 1560px) {
                width: 100%;
                padding-left: 15px;
                padding-bottom: 145px;
            }


            p {
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: 0.16px;

                &:first-child {
                    margin-bottom: 16px;
                }

                @media (max-width: 480px) {
                    font-size: 13px;
                    letter-spacing: 0.13px;
                }

                @media (min-width: 481px) and (max-width: 860px) {
                    margin-top: 22px;
                    font-size: 14px;
                    letter-spacing: 0.14px;
                }

                @media (min-width: 861px) and (max-width: 1560px) {
                    font-size: 14px;
                    max-width: 357px;
                }

            }
        }
    }
}