@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import './vars.scss';

@font-face {
  font-family: 'Articulat CF';
  src: url('./assets/fonts/ArticulatCF-Bold.woff2') format('woff2'),
       url('./assets/fonts/ArticulatCF-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('./assets/fonts/ArticulatCF-DemiBold.woff2') format('woff2'),
       url('./assets/fonts/ArticulatCF-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('./assets/fonts/ArticulatCF-ExtraBold.woff2') format('woff2'),
       url('./assets/fonts/ArticulatCF-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('./assets/fonts/ArticulatCF-ExtraLight.woff2') format('woff2'),
       url('./assets/fonts/ArticulatCF-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('./assets/fonts/ArticulatCF-Heavy.woff2') format('woff2'),
       url('./assets/fonts/ArticulatCF-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('./assets/fonts/ArticulatCF-Light.woff2') format('woff2'),
       url('./assets/fonts/ArticulatCF-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('./assets/fonts/ArticulatCF-Medium.woff2') format('woff2'),
       url('./assets/fonts/ArticulatCF-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('./assets/fonts/ArticulatCF-Regular.woff2') format('woff2'),
       url('./assets/fonts/ArticulatCF-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('./assets/fonts/ArticulatCF-Thin.woff2') format('woff2'),
       url('./assets/fonts/ArticulatCF-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

body {
  font-family: 'Manrope', sans-serif;
  color: var(--text);
  margin: 0;

  &.unavailable {
    header {
      display: none !important;
    }

    .footer__content {
      display: none;
    }
  }
}

q {
  font-style: italic;
}

ul, p {
  margin: 0;
  padding: 0;
}

svg:hover {
  path {
    transition: all 0.3s ease;
    fill: #CB51FF;
  }
}

a {
  text-decoration: none;
  color: var(--text);
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.14px;
  text-transform: uppercase;
}

h1, h2, h3 {
  margin: 0;
}

.overflow {
  height: 100vh !important;
  overflow: hidden !important;
}

button {
  text-transform: uppercase;
  font-family: 'Manrope';
  font-weight: 800;
  font-size: 19px;
  padding: 12px 20px;
  color: #fff;
  border-radius: 49px;
  background: linear-gradient(270deg, #5CF4FF 0%, #BB4FFF 57.81%, #FC58FF 100%), linear-gradient(270deg, #90EBF1 0%, #AA6EFF 50.86%, #F467F6 100%), linear-gradient(270deg, #8FDADF 0%, #8FDADF 23.96%, #AA6EFF 100%);
  cursor: pointer;
  transition: background 0.3s ease;
  border: none;

  &:hover {
    background: linear-gradient(270deg, #FFF068 0%, #C274DE 51.04%, #A261FF 80.21%), linear-gradient(270deg, #8FDADF 0%, #8FDADF 23.96%, #AA6EFF 100%);
  }
}

.desktop {
  @media (max-width: 480px) {
    display: none !important;
  }
}

.mobile {
  display: none !important;

  @media (max-width: 480px) {
    display: flex !important;
    width: 100%;
  }
}

.container {
  @media (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 90% !important;
  }
}

a:not(.ignore):hover {
  background: -webkit-linear-gradient(0deg,#E065FF, #6AAFFF);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  .icon {
    color:#5CF4FF;
  }
}




.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Manrope';
  font-weight: 800;
  font-size: 19px;
  padding: 12px 20px;
  color: #fff;
  border-radius: 49px;
  overflow: hidden;
  cursor: pointer;

  span {
    z-index: 10;
  }
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  border-radius: 49px;
}

.gradient1 {
  background: linear-gradient(270deg, #5CF4FF 0%, #BB4FFF 57.81%, #FC58FF 100%);
  border-radius: 49px;
  opacity: 1;
}

.gradient2 {
  background: linear-gradient(270deg, #FFF068 0%, #C274DE 51.04%, #A261FF 80.21%);
  border-radius: 49px;
  opacity: 0;
}

.button:hover .gradient1 {
  opacity: 0;
}

.button:hover .gradient2 {
  opacity: 1;
}

.onlyTablet {
  display: none !important;

  @media (min-width: 481px) and (max-width: 860px) {
    display: flex !important;
  }
}

.unknownPage {
  height: 100vh;
  background-image: url('./assets/404bg.jpg');
  background-size: cover;

  img {
    margin-top: 52px;
  }
  &__gradient {
    background-image: url('./assets/404gradient.png');
    background-size: cover;
    width: 1084px;
    height: 646px;
    position: absolute;
    right: -70px;
    bottom: 0;
    opacity: 0.5;

    @media (max-width: 480px) {
      width: 583.114px;
      height: 359.878px;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h2 {
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.3px;
      background: -webkit-linear-gradient(0deg, #4328BC, #2F87ED 70%, #9BF9FF 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-top: 118px;
    }

    p {
      font-family: Manrope;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.16px;
      margin-top: 16px;
    }

    .button {
      width: 282px;
      margin-top: 30px;
    }
  }
}
