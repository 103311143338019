.fifth {
    display: flex;
    flex-direction: column;
    margin-top: 180px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @media (max-width: 480px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .button {
            width: 310px;

            @media (max-width: 480px) {
                width: 170px;
                margin-top: 30px;
                font-size: 12px;
            }

            @media (min-width: 861px) and (max-width: 1560px) {
                width: 279px;
                font-size: 15px;
            }
        }
    }
}