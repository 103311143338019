.third {
    display: flex;
    flex-direction: column;
    margin-top: 150px;


    @media (max-width: 480px) {
        margin-top: 50px;
    }

    @media (min-width: 481px) and (max-width: 860px) {
        margin-top: 118px;
    }

    @media (min-width: 861px) and (max-width: 1560px) {
        margin-top: 100px;
    }

    
    &__content {
      margin-top: 65px;

        @media (max-width: 480px) {
            margin-top: 38px;
        }

        @media (min-width: 481px) and (max-width: 860px) {
            margin-top: 35px;
        }

        @media (min-width: 861px) and (max-width: 1560px) {
            margin-top: 30px;
        }

      &--desc {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.16px;
        max-width: 530px;

        @media (max-width: 480px) {
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: 0.13px;
            padding-right: 20px;
        }

        @media (min-width: 481px) and (max-width: 860px) {
            font-size: 14px;
            letter-spacing: 0.14px;
        }

        @media (min-width: 861px) and (max-width: 1560px) {
            font-size: 14px;
            letter-spacing: 0.14px;
            max-width: 418px;
        }
      }

      &--body {
        margin-top: 65px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 480px) {
            flex-direction: column;
            justify-content: flex-start;
        }

        @media (min-width: 861px) and (max-width: 1560px) {
            margin-top: 50px;
        }

        &__list {
            width: 32%;
            display: flex;
            flex-direction: column;

            @media (max-width: 480px) {
                width: 100%;
            }

            @media (min-width: 481px) and (max-width: 860px) {
                width: 48%;
            }

            &.secondList {
                margin-top: 260px;

                @media (max-width: 480px) {
                    margin-top: 0px;
                }

                @media (min-width: 481px) and (max-width: 860px) {
                    margin-top: 120px;
                }

                @media (min-width: 861px) and (max-width: 1560px) {
                    margin-top:200px;
                }
            }

            &.thirdList {
                margin-top: 130px;

                @media (max-width: 480px) {
                    margin-top: 0px;
                }

                @media (min-width: 481px) and (max-width: 860px) {
                    display: none;
                }

                @media (min-width: 861px) and (max-width: 1560px) {
                    margin-top: 100px;
                }
            }

            &--item {
                display: flex;
                flex-direction: column;
                border-radius: 41px;
                background: linear-gradient(0deg, #F8F8F8 0%, #F8F8F8 100%), linear-gradient(0deg, rgba(255, 252, 224, 0.05) 0%, rgba(255, 253, 234, 0.50) 100%);
                padding: 50px 50px 50px;
                margin-bottom: 26px;

                @media (max-width: 480px) {
                    padding: 30px 30px 30px;
                }

                @media (min-width: 481px) and (max-width: 860px) {
                    padding: 30px 30px 30px;
                }

                @media (min-width: 861px) and (max-width: 1560px) {
                    padding-bottom: 30px;
                }

                img {
                    max-width: 150px;
                    height: auto;
                    margin-bottom: 24px;
                    align-self: flex-start;
                    justify-self: center;


                    @media (max-width: 480px) {
                        margin-bottom: 12px;
                    }
                }

                &__title {
                    color: #4328BC;
                    leading-trim: both;
                    text-edge: cap;
                    font-family: Manrope;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 30px;
                    letter-spacing: 0.24px;
                    background: -webkit-linear-gradient(0deg,#4328BC,#2F87ED, #9BF9FF);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;


                    @media (max-width: 480px) {
                        font-size: 18px;
                    }

                    @media (min-width: 481px) and (max-width: 860px) {
                        font-size: 19px;
                    }

                    @media (min-width: 861px) and (max-width: 1560px) {
                        font-size: 20px;
                    }
                }

                &__text {
                    margin-top: 12px;
                    font-family: Manrope;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                    letter-spacing: 0.16px;
                    max-width: 80%;

                    @media (max-width: 480px) {
                        font-size: 13px;
                        max-width: 90%;
                    }

                    @media (min-width: 481px) and (max-width: 860px) {
                        font-size: 14px;
                        max-width: 90%;
                    }

                    @media (min-width: 861px) and (max-width: 1560px) {
                        font-size: 14px;
                        max-width: 90%;
                    }
                }
            }
        }
      }
    }
}