.first {
    display: flex;
    height: fit-content;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    padding-top: 70px;

    @media (max-width: 480px) {
        flex-direction: column-reverse;
    }

    @media (min-width: 481px) and (max-width: 860px) {
        flex-direction: column-reverse;
    }

    @media (min-width: 1560px) {
        height: 70vh;
        min-height: 1100px;
    }

    &__left {
        width: 50%;
        display: flex;
        flex-direction: column;

        @media (max-width: 480px) {
            width: 100%;
        }

        @media (min-width: 481px) and (max-width: 860px) {
            width: 100%;
        }

        &--buttons {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 60px;
        }

        &--button1 {
            width: 160px !important;
            @media (min-width: 861px) and (max-width: 1024px) {
                width: 110px !important;
            }
        }

        &--research {
            width: 300px;
            height: 44px !important;
            border: 2px solid #7B51D4;
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            font-family: 'Manrope';
            font-weight: 800;
            font-size: 19px;
            color: #7B51D4;
            border-radius: 49px;
            height: fit-content;
            margin-left: 16px;
            cursor: pointer;

            .icon {
                margin-left: 2px;
                @media (max-width: 861px) {
                    display: none;
                }
            }

            @media (max-width: 480px) {
                font-size: 12px;
                width: 280px;
                height: 40px !important;

                span {
                    display: none !important;
                }
            }

            @media (max-width: 861px) {
                font-size: 13px;
                width: 250px;
            }

            @media (min-width: 861px) and (max-width: 1560px) {
                font-size: 15px;
                width: 300px;
            }

            @media (min-width: 1560px) {
                width: 330px;
            }

            span {
                color: #FFF;
                text-align: center;
                font-family: Manrope;
                font-size: 10px;
                font-style: normal;
                font-weight: 800;
                line-height: 100%; /* 10px */
                letter-spacing: 0.2px;
                text-transform: uppercase;
                border-radius: 5px;
                background: linear-gradient(270deg, #FFF068 9.33%, #C274DE 34.02%, #A261FF 100%), #FFF;
                margin-left: 6px;
                width: 36px;
                height: 17px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        @keyframes textShine {
            0% {
              background-position: 0% 50%;
            }
            100% {
              background-position: 100% 50%;
            }
        }


        h1 {
            font-family: Articulat CF;
            font-size: 80px;
            font-style: normal;
            font-weight: 800;
            line-height: 98px;

            background: -webkit-linear-gradient(135deg,#FFAA8F, #6E24E7, #6E24E7, #51B6FF, #6E24E7, #5AAAE3, #C38CD7, #FFAA8F);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: 500% auto;
            animation: textShine 4s ease-in-out infinite alternate;

            @media (max-width: 480px) {
                font-size: 38px;
                font-style: normal;
                font-weight: 800;
                line-height: 45px;
                max-width: 70%;
            }

            @media (min-width: 481px) and (max-width: 860px) {
                font-size: 58px;
                line-height: 73px;
                max-width: 50%;
            }
            
            @media (min-width: 861px) and (max-width: 1560px) {
                font-size: 64px;
                line-height: 77.801px;
                max-width: 390px;
            }

        }

        p {
            margin-top: 8px;
            font-size: 26px;
            font-style: normal;
            font-weight: 600;
            line-height: 40.5px;
            letter-spacing: 0.26px;

            @media (max-width: 480px) {
                font-size: 17px;
                font-style: normal;
                font-weight: 600;
                line-height: 22.167px;
                letter-spacing: 0.17px;
                max-width: 70%;
            }

            @media (min-width: 481px) and (max-width: 860px) {
                font-size: 20px;
                line-height: 25px;
            }

            @media (min-width: 861px) and (max-width: 1560px) {
                font-size: 22px;
                line-height: 32px;
                max-width: 377px;
            }
        }

        .button {
            width: 282px;

            @media (max-width: 480px) {
                width: 170px;
                font-size: 12px;
            }

            @media (min-width: 481px) and (max-width: 860px) {
                width: 191px;
                font-size: 13px;
            }

            @media (min-width: 861px) and (max-width: 1560px) {
                width: 223px;
                font-size: 15px;
            }
        }

        &--powered {
            margin-top: 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: var(--textSecond);

            @media (min-width: 481px) and (max-width: 860px) {
                margin-top: 10px;
                font-size: 10px;
            }

            @media (max-width: 480px) {
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 14.313px;
                letter-spacing: 0.1px;
            }

            @media (min-width: 861px) and (max-width: 1560px) {
                margin-top: 14px;
                font-size: 12px;
            }

            img {
                margin-left: 8px;

                @media (max-width: 480px) {
                    width: 110px;
                    height: 13px;
                }

                @media (min-width: 481px) and (max-width: 860px) {
                    width: 125px;
                    height: 14px;
                }

                @media (min-width: 861px) and (max-width: 1560px) {
                    width: 145px;
                    height: 16px;
                }
            }
        }
    }

    &__right {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            max-width: 100%;
            max-height: 619px;
            z-index: 1;
        }

        &::before {
            content: '';
            background-image: url('../../assets/smallLight.png');
            position: absolute;
            top: -30%;
            left: -50%;
            display: block;
            width: 100%;
            height: 200px;
            background-size: cover;

        }

        &::after {
            content: '';
            background-image: url('../../assets/light-min.png');
            position: absolute;
            right: -90%;
            bottom: -450px;
            display: block;
            width: 130%;
            height: 487.29px;
            background-size: cover;
            // z-index: -1;

            @media (max-width: 480px) {
                right: -80%;
                bottom: -92%;
            }
        }

        @media (min-width: 481px) and (max-width: 860px) {
            width: 100%;
            justify-content: flex-end;
            position: relative;
            left: 25px;
        }

        @media (max-width: 480px) {
            width: 100%;

            img {
                width: 100%;
                padding-bottom: 20px;
            }

        }
    }
}